import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { MostValuableCustomerData } from 'types/analytics';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

type Values = {
  start: string;
  finish: string;
  orderType: string;
};

export const useMostValuableCustomersQuery = (
  shopId: string,
  values: Values,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [
      shopId,
      {
        startDate: values.start,
        endDate: values.finish,
        orderType: values.orderType,
      },
      'metrics-sales-by-day',
    ],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/metrics/most_valuable_customers?${queryString.stringify(
          snakeCaseKeys({
            ...values,
            shouldUseOpas: true,
          }),
        )}`,
      );
      return camelCaseKeys(response) as MostValuableCustomerData;
    },
  });
};
