import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Pause } from 'types/shop-hours';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getOpenForTodayQueryKey } from './use-open-for-today';
import { getPausingsQueryKey } from './use-pausings';

type Values = {
  pausingId: string;
  updatedEndTime: string;
};

export const useUpdatePauseMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ pausingId, updatedEndTime }: Values) => {
      const response = await authenticatedFetch.patch(
        `api/management/v1/shops/${shopId}/pausings/${pausingId}`,
        snakeCaseKeys({ endTime: updatedEndTime }),
      );
      return camelCaseKeys(response.data) as Pause;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getPausingsQueryKey(shopId), [data]);
      queryClient.invalidateQueries(getOpenForTodayQueryKey(shopId));
    },
  });
};
