import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Logomark } from 'crust';

import Label from 'components/shared/label';
import { RHFTextField } from 'components/shared/rhf-text-field';
import Select from 'components/shared/slice-select';
import { useCurrentUserQuery } from 'hooks/shared';
import { useShopsQuery } from 'hooks/shops';
import * as paths from 'routes/paths';
import { ChooseShopOption } from 'types/shops';

import styles from './styles.module.scss';

const ChooseShop = () => {
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      shopId: '',
    },
  });

  const { data: shops } = useShopsQuery();
  const { data: user } = useCurrentUserQuery({ enabled: true });

  const [activeOption, setActiveOption] = useState<ChooseShopOption | null>(
    null,
  );

  const options = useMemo(
    () =>
      shops?.map(
        (shop): ChooseShopOption => ({
          value: shop.shopId,
          label: shop.name,
          subLabel: `${shop.address}, ${shop.city}, ${shop.state} ${shop.zipcode}`,
        }),
      ) ?? [],
    [shops],
  );

  const formatOptionLabel = useCallback(
    (option: ChooseShopOption, { context }: { context: string }) => {
      if (context === 'value') {
        return option.label;
      }

      return (
        <>
          <div className={styles.label}>{option.label}</div>
          <div className={styles.subLabel}>{option.subLabel}</div>
        </>
      );
    },
    [],
  );

  useEffect(() => {
    const shop = shops?.at(0);

    if (shop && shops?.length === 1) {
      navigate(paths.shop(shop.shopId));
    }
  }, [shops, navigate]);

  useEffect(() => {
    if (activeOption == null) {
      const defaultOption = options.at(0);

      if (defaultOption) {
        setActiveOption(defaultOption);
      }
    }
  }, [activeOption, options]);

  const handleContinueClick = () => {
    if (!activeOption) {
      return;
    }

    const shopUrl = paths.shop(activeOption.value);
    navigate(shopUrl);
  };

  const onSubmit = ({ shopId }: { shopId: string }) => {
    const shopUrl = paths.shop(shopId);
    navigate(shopUrl);
  };

  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <Logomark className={styles.logo} />
        <div className={styles.text}>
          {user && user.userType === 'administrator'
            ? 'Input the id of the shop that you want to access'
            : 'Select which one of your shops you would like to view the profile for'}
        </div>

        {user && user.userType === 'administrator' ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField
              control={control}
              name="shopId"
              placeholder="Enter Shop Id"
              className={styles.textField}
            />
            <Button
              className={styles.button}
              type="submit"
              data-testid="shopSelectionPageContinueButton"
            >
              Continue
            </Button>
          </form>
        ) : (
          <>
            <Label htmlFor="shop-select">Select your shop</Label>
            <Select
              className={styles.select}
              inputId="shop-select"
              formatOptionLabel={formatOptionLabel}
              onChange={setActiveOption}
              options={options}
              value={activeOption}
              data-testid="shopSelectionPageDropdownInput"
            />

            <Button
              className={styles.button}
              isDisabled={!activeOption}
              onPress={handleContinueClick}
              data-testid="shopSelectionPageContinueButton"
            >
              Continue
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ChooseShop;
