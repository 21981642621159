import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Modal from 'components/shared/slice-modal';
import { useDeleteClosingsMutation } from 'hooks/hours/use-delete-closings-mutation';
import useAnalytics from 'hooks/use-analytics';

import styles from './styles.module.scss';

const DeleteModal = ({
  activeClosingId,
  deletingRecord,
  setActiveClosingId,
  setDeletingRecord,
  shopId,
}) => {
  const { trackDeleteClosing } = useAnalytics();

  const { mutate: deleteClosing, isLoading: isDeleteInProgress } =
    useDeleteClosingsMutation(shopId);

  const handleDeleteSubmit = () =>
    deleteClosing(deletingRecord.id, {
      onSuccess: () => {
        trackDeleteClosing(shopId, deletingRecord, true);
        if (activeClosingId === deletingRecord.id) {
          setActiveClosingId(null);
        }
        setDeletingRecord(null);
      },
      onError: () => {
        trackDeleteClosing(shopId, deletingRecord, false);
        setDeletingRecord(null);
        toast.error(
          'An error occurred while deleting this record. Try refreshing!',
        );
      },
    });

  return (
    <Modal
      header="Are you sure?"
      isOpen={deletingRecord ? true : false}
      isYesButtonDisabled={isDeleteInProgress}
      onClickNo={() => setDeletingRecord(null)}
      onClickYes={handleDeleteSubmit}
      onRequestClose={() => setDeletingRecord(null)}
      yesButtonText="Delete"
    >
      <div className={styles.modalContent}>
        {`You are about to delete your closing between ${deletingRecord?.startDate} and
        ${deletingRecord?.endDate}. This action cannot be undone.`}
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  activeClosingId: PropTypes.number,
  deletingRecord: PropTypes.object,
  setActiveClosingId: PropTypes.func.isRequired,
  setDeletingRecord: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
};

DeleteModal.defaultProps = {
  activeClosingId: null,
  deletingRecord: {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DeleteModal;
