import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { upsertBy } from 'utilities/lists';
import { camelCaseKeys } from 'utilities/objects';

type Values = [
  {
    day_of_week: string;
    from: string;
    id: number | null;
    isDeleted?: boolean;
    open_for: string;
    to: string;
    type: string;
  },
];

export type Opening = {
  id: number;
  from: string;
  to: string;
  dayOfWeek: string;
  openFor: string;
  effectiveDate: string | null;
};

export const useUpdateShopOpeningMutaion = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (entries: Values) => {
      const requests = [];
      console.log(entries);

      for (const values of entries) {
        if (values.type === 'update') {
          requests.push(
            await authenticatedFetch
              .put(`api/management/v1/shops/${shopId}/openings/${values.id}`, {
                opening: values,
              })
              .then(camelCaseKeys),
          );
        } else {
          requests.push(
            await authenticatedFetch
              .post(`api/management/v1/shops/${shopId}/openings`, {
                opening: values,
              })
              .then(camelCaseKeys),
          );
        }
      }

      return Promise.all(requests);
    },
    onSuccess: (responses) => {
      for (const data of responses) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        queryClient.setQueryData<Opening[]>(
          [shopId, 'weeklyHours'],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (oldHours) => {
            console.log(oldHours);
            console.log(data, 'data');
            if (oldHours !== undefined) {
              const upsertOldHours = upsertBy(oldHours, data, 'id');
              return upsertOldHours;
            }
          },
        );
      }
    },
  });
};
