import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { formatClosingForTable } from 'utilities/closings';
import { insert } from 'utilities/lists';
import { camelCaseKeys } from 'utilities/objects';

import { Closings, ClosingsResponse } from './use-closings';
import { CachedClosingsData } from './use-create-closings-mutation';

type Values = {
  closingParams: ClosingsResponse;
  closingId: number;
};

export const useUpdateClosingsMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ closingParams, closingId }: Values) => {
      const response = await authenticatedFetch.put(
        `api/management/v1/shops/${shopId}/closings/${closingId}`,
        closingParams,
      );

      return camelCaseKeys(response) as Closings;
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [shopId, 'closings'],
        (cachedClosingsData: CachedClosingsData[] | undefined) => {
          if (cachedClosingsData) {
            return insert(
              cachedClosingsData,
              formatClosingForTable(response),
            ) as CachedClosingsData[];
          }
        },
      );

      // We invalidate the query to refetch the 'open_for_today' values. The backend calculates values needed
      // such as next_open_at which may be impacted by closing updates
      queryClient.invalidateQueries([shopId, 'openForToday']);
    },
  });
};
