import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  formatClosingReasonsForPause,
  formatClosingReasonsForSelect,
} from 'utilities/closings';

export enum ClosingScope {
  Pause = 'pause',
  Closing = 'closing',
}

// The return type of the hook depends on the given scope. Until the utility
// file is converted to TS and the types can be cleaned up, the conditional
// types will be specified here.
export type SelectClosingReason = {
  id: number;
  label: string;
  value: number;
};

export type PauseClosingReason = {
  id: number;
  label: string;
};

export const useClosingReasons = (scope: ClosingScope) => {
  const { authenticatedFetch } = useApi();

  return useQuery([`closingReasons-${scope}`], async () => {
    const closingReasons = await authenticatedFetch.get(
      `api/management/v1/closure_reasons${scope ? `?scope=${scope}` : ''}`,
    );

    if (scope === ClosingScope.Pause) {
      return formatClosingReasonsForPause(
        closingReasons,
      ) as PauseClosingReason[];
    }

    return formatClosingReasonsForSelect(
      closingReasons,
    ) as SelectClosingReason[];
  });
};
