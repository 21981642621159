import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ClosingForm from 'components/hours/closings/closing-form';
import { useUpdateClosingsMutation } from 'hooks/hours/use-update-closings-mutation';
import useAnalytics from 'hooks/use-analytics';
import { formatClosingForApi } from 'utilities/closings';
import { showInvalidSubmitToast } from 'utilities/forms';

const EditClosingTile = ({
  activeClosingId,
  defaultValues,
  onClose,
  shopId,
  shopTimezone,
}) => {
  const { trackUpdateClosing } = useAnalytics();

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    setError,
  } = useForm({ defaultValues, mode: 'onBlur' });

  const { mutate: updateClosing } = useUpdateClosingsMutation(shopId);

  const handleSubmitUpdateClosing = (values) =>
    updateClosing(
      {
        closingsParams: formatClosingForApi(values),
        closingId: activeClosingId,
      },
      {
        onSuccess: (response) => {
          trackUpdateClosing(shopId, response, true);
          onClose();
        },
        onError: (error) => {
          handleRequestError(error.responseBody, setError);
        },
      },
    );

  const handleRequestError = (responseBody, setError) => {
    if (
      responseBody?.status === 422 &&
      responseBody?.messages.some((it) =>
        it.includes('lie within another Closing'),
      )
    ) {
      setError('dates', {
        type: 'manual',
        message: 'The dates cannot overlap with an existing closing.',
      });
      showInvalidSubmitToast();
    } else {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <ClosingForm
      action="edit"
      control={control}
      errors={errors}
      isSubmitting={isSubmitting}
      onClickCancel={onClose}
      onSubmit={handleSubmit(handleSubmitUpdateClosing, showInvalidSubmitToast)}
      shopTimezone={shopTimezone}
    />
  );
};

EditClosingTile.propTypes = {
  activeClosingId: PropTypes.number,
  defaultValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  shopTimezone: PropTypes.string.isRequired,
};

EditClosingTile.defaultProps = {
  activeClosingId: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default EditClosingTile;
