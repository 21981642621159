import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';

import { getDailyHoursQueryKey } from './use-daily-hours';

type Values = {
  todayOrTomorrow: string;
  hours: Hours;
  effectiveDate: string;
  shippingType: string;
};

type Hours = [{ from: string; to: string }];
export const useUpdateDailyhoursMutation = (shopId: string) => {
  const queryClient = useQueryClient();
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (values: Values) => {
      const params = {
        daily_hours: {
          hours: values.hours,
          effective_date: values.effectiveDate,
          open_for: values.shippingType,
        },
      };

      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/daily_hours`,
        params,
      );
      
      return response.hours as Hours;
    },
    onSuccess: (data, values) => {
      // For the status indicator we are driven by the open_for_today values so after posting to
      // daily hours we want to refetch the open for today values based on the new special hours
      queryClient.invalidateQueries([shopId, 'openForToday']);
      queryClient.setQueryData(
        getDailyHoursQueryKey(shopId),
        // TODO: Figure out the type of old
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        (old) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-expect-error
          old[values.todayOrTomorrow][values.shippingType] = data;

          return old;
        },
      );
    },
  });
};
