import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { DailyHours } from 'types/daily-hours';
import { camelCaseKeys } from 'utilities/objects';

export const getDailyHoursQueryKey = (shopId: string): QueryKey => [
  shopId,
  'dailyHours',
];

export const useDailyHoursQuery = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getDailyHoursQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/daily_hours`,
      );

      return camelCaseKeys(response) as DailyHours;
    },
  });
};
