import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { snakeCaseKeys } from 'utilities/objects';

type Values = {
  start: string;
  finish: string;
  orderType: string;
};

export const useOrderTotalsByShippingTypeMetricsQuery = (
  shopId: string,
  values: Values,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [
      shopId,
      {
        startDate: values.start,
        endDate: values.finish,
        orderType: values.orderType,
      },
      'metrics-order-totals',
    ],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/metrics/order_totals_by_shipping_types?${queryString.stringify(
          snakeCaseKeys({
            ...values,
            shouldUseOpas: true,
          }),
        )}`,
      );
      return response.data;
    },
  });
};
