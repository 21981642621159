import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

import { CachedClosingsData } from './use-create-closings-mutation';

export const useDeleteClosingsMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (closingId: number) => {
      await authenticatedFetch.del(
        `/api/management/v1/shops/${shopId}/closings/${closingId}`,
      );
    },
    onSuccess: (_, closingId) => {
      queryClient.setQueryData(
        [shopId, 'closings'],
        (cachedClosingsData: CachedClosingsData[] | undefined) => {
          if (cachedClosingsData) {
            return cachedClosingsData.filter(
              (closing) => closing.id !== closingId,
            );
          }
        },
      );
      // We invalidate the query to refetch the 'open_for_today' values. The backend calculates values needed
      // such as next_open_at which may be impacted by closing updates
      queryClient.invalidateQueries([shopId, 'openForToday']);
    },
  });
};
