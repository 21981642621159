import PropTypes from 'prop-types';

import AnalyticsTile from 'components/shared/analytics-tile';
import { useMostValuableCustomersQuery } from 'hooks/metrics/use-most-valuable-customers-query';
import EmptyCustomersIcon from 'images/empty-graphs/customers.svg?react';
import * as paths from 'routes/paths';
import { formatMostValuableCustomers } from 'utilities/analytics';

import Table from './table';
import TableRowContent from './table-row-content';

import styles from './styles.module.scss';

const MostValuableCustomers = ({ shopId, startDate, endDate, orderType }) => {
  const { data, isLoading } = useMostValuableCustomersQuery(shopId, {
    start: startDate,
    finish: endDate,
    orderType,
  });

  const formattedData = formatMostValuableCustomers(data);

  const isEmpty = isLoading || formattedData.length === 0;

  return (
    <AnalyticsTile
      title="Most Valuable Customers"
      footerText="Keep your customers engaged by creating social media posts with our free graphics."
      linkText="Download Social Media Graphics"
      link={paths.shopMarketing(shopId)}
    >
      <div className={styles.content}>
        {isEmpty ? (
          <div className={styles.placeholder}>
            <EmptyCustomersIcon className={styles.placeholderIcon} />
            <div className={styles.placeholderText}>
              No results yet. Check back soon!
            </div>
          </div>
        ) : (
          <Table
            data={formattedData.slice(0, 4)}
            rowContent={TableRowContent}
          />
        )}
      </div>
    </AnalyticsTile>
  );
};

MostValuableCustomers.propTypes = {
  shopId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
  showTileCaption: PropTypes.bool,
};

MostValuableCustomers.defaultProps = {
  shopId: '',
  orderType: 'online',
  showTileCaption: true,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MostValuableCustomers;
