/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from 'query-string';

import { ShopProfile } from 'types/brand-manager';
import { Schedule } from 'types/shop-hours';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

// This typescript rule is disabled to allow for type inferring of the API. This means in typescript files
// that make use of the useAPI hook, if the specific api function is typed the returned data will also be typed.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const create = (
  authenticatedFetch: {
    get: any;
    put: any;
    patch: any;
    post: any;
    del: any;
    delete: any;
    postFile: any;
  },
  hostnames: { restaurantApi: any },
) => ({
  // Even though the confirmation metric tile has been removed, we intend to use
  // this endpoint again in the future.
  // https://app.clubhouse.io/slicelife/story/220935/remove-confirmation-metric-tile-and-redux-store
  getShopConfirmationMetrics: (shopId: any, intervalEndDate: any) => {
    const queryStr = queryString.stringify({
      interval_end_date: intervalEndDate,
    });
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/confirmation_time?${queryStr}`,
      )
      .then((response: { confirmation_metrics: any }) => ({
        // The corresponding saga wants to index the response by shopId, but a singular nested
        // object is returned. Putting the returned metrics into an array satisifies the saga
        // expectations for now.
        response: [camelCaseKeys(response.confirmation_metrics)],
      }))
      .catch((error: any) => ({ error }));
  },

  getShopOrderSummary: (
    shopId: any,
    startDate: any,
    endDate: any,
    shopTimezone: any,
  ) => {
    const queryStr = queryString.stringify({
      end: endDate,
      start: startDate,
      timezone: shopTimezone,
    });
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/orders/by/day?${queryStr}`,
      )
      .then(camelCaseKeys);
  },

  generateShopSchedule: (
    shopId: string,
    generateScheduleParams: any,
  ): Promise<Schedule> =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/v1/shops/${shopId}/schedule/generate`,
        generateScheduleParams,
      )
      .then(camelCaseKeys),
  updateShopOpenForToday: (shopId: any, openForTodayParams: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/open_for_today`,
        openForTodayParams,
      )
      .then(({ data }: any) => ({ response: camelCaseKeys(data) }))
      .catch((error: any) => ({ error })),

  getCurrentUser: () =>
    authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/management/v1/me`)
      .then(camelCaseKeys),

  fetchProductSalesMetrics: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/product_sales?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => response.data),

  sendEmail: (shopId: string, subject: string, to: string) =>
    authenticatedFetch
      .post(`${hostnames.restaurantApi}/api/management/v1/email/send_email`, {
        subject,
        to,
        shop_id: shopId,
      })
      .then(camelCaseKeys),

  // Brand manager
  getShopProfile: (shopId: string): ShopProfile => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/brand_manager/shop_profile/${shopId}`,
      )
      .then((response: ShopProfile): ShopProfile => {
        return response;
      });
  },

  deleteRegisterUser: (userId: string, shopId: string) => {
    console.log(
      `I would have sent a delete request to ${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
    );
    // TODO - When backend endpoint is in place uncomment the actual api call below
    // return authenticatedFetch.del(
    //   `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
    // );
    return Promise.resolve(userId);
  },
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default create;
