import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { formatClosingForTable } from 'utilities/closings';
import { insert } from 'utilities/lists';
import { camelCaseKeys } from 'utilities/objects';

import { Closings, ClosingsResponse } from './use-closings';

export type CachedClosingsData = {
  id: number;
  startDate: string;
  endDate: string;
  shippingType: string;
  reason: string;
  source: string;
  closureReasonId: number;
  ClosureReasonName: string;
};

export const useCreateClosingsMutaion = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (closingParams: ClosingsResponse) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/closings`,
        closingParams,
      );

      return camelCaseKeys(response) as Closings;
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [shopId, 'closings'],
        (cachedClosingsData: CachedClosingsData[] | undefined) => {
          if (cachedClosingsData) {
            return insert(
              cachedClosingsData,
              formatClosingForTable(response.data),
            ) as CachedClosingsData[];
          }
        },
      );

      // We invalidate the query to refetch the 'open_for_today' values. The backend calculates values needed
      // such as next_open_at which may be impacted by closing updates
      queryClient.invalidateQueries([shopId, 'openForToday']);
    },
  });
};
