import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Pause } from 'types/shop-hours';
import { camelCaseKeys } from 'utilities/objects';

export const getPausingsQueryKey = (shopId: string): QueryKey => [
  shopId,
  'pausings',
];

export const usePausingsQuery = (
  shopId: string,
  options?: UseQueryOptions<Pause[]>,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getPausingsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/pausings`,
      );
      return camelCaseKeys(response.data) as Pause[];
    },

    ...options,
  });
};
