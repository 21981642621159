import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

export const getOpeningsQueryKey = (shopId: string): QueryKey => [
  shopId,
  'weeklyHours',
];

export const useOpeningsQuery = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getOpeningsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/openings`,
      );
      return camelCaseKeys(response);
    },
  });
};
