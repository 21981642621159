import PropTypes from 'prop-types';

import { useCurrentUserQuery } from 'hooks/shared';
import { useShopQuery, useShopsQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import useFeatureFlag from 'hooks/use-feature-flag';
import { useWindowSize } from 'hooks/use-window-size';
import { useVerificationCodeQuery } from 'hooks/verification-code/use-verification-code-query';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import { getShopHasAnyRegister } from 'utilities/shops';

import NavigationDesktop from './desktop';
import NavigationMobile from './mobile';
import * as navItems from './nav-items';

const Navigation = ({ shopId }) => {
  const { data: shops } = useShopsQuery();
  const { data: activeShop } = useShopQuery(shopId);
  const { data: user } = useCurrentUserQuery({ enabled: true });
  const analytics = useAnalytics();

  const { isEnabled: isBrandManagerPageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.BrandManager,
  );

  const { isEnabled: isHoursAnalyticsEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.HoursAnalytics,
  );

  const { isEnabled: isUpsellAMSEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.ShouldUpsellAMS,
  );

  const { isEnabled: isCustomerProfilesPageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.OwnersPortalCustomerProfilesPage,
  );

  const { isEnabled: isGoogleBusinessProfilePageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.GoogleBusinessProfile,
  );

  const { isEnabled: isPhonePageEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.PhonePage,
  );

  const { data: verificationCodeData } = useVerificationCodeQuery(shopId);

  const trackNavigationItemClick = (labels, isExternalLink) => {
    analytics.trackNavigationItemClick(
      shopId,
      labels,
      isExternalLink,
      isRegisterUser,
      registerMode,
    );
  };

  const isRegisterUser =
    activeShop && (getShopHasAnyRegister(activeShop) ?? false);
  const registerMode = activeShop && activeShop.registerMode.name;

  const { isMobile } = useWindowSize();

  const navigationItems = [
    navItems.topSection(shopId, isHoursAnalyticsEnabled),
    navItems.secondSection(shopId, isCustomerProfilesPageEnabled),
    navItems.thirdSection(
      shopId,
      isBrandManagerPageEnabled,
      isUpsellAMSEnabled,
      isGoogleBusinessProfilePageEnabled,
    ),
    navItems.phoneSection(shopId, isPhonePageEnabled),
    navItems.operationsSection(shopId),
    navItems.accountsSection(shopId, activeShop?.slicePayments),
  ].filter((item) => item != null);

  if (activeShop) {
    const registerNavItems = navItems.registerSection({
      shop: activeShop,
    });

    navigationItems.splice(3, 0, registerNavItems);
  }

  const NavigationComponent = isMobile ? NavigationMobile : NavigationDesktop;

  return (
    <NavigationComponent
      activeShop={activeShop}
      navigationItems={navigationItems}
      shopId={shopId}
      shops={shops ?? []}
      trackNavigationItemClick={trackNavigationItemClick}
      verificationCode={verificationCodeData?.verificationCode}
      isAdmin={user && user.userType === 'administrator'}
    />
  );
};

Navigation.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Navigation;
