import { useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { Moment } from 'moment-timezone';

import { Radio, RadioGroup } from 'crust';

import Label from 'components/shared/label';
import Modal from 'components/shared/slice-modal';
import Select from 'components/shared/slice-select';
import Suspended from 'components/shared/suspended';
import {
  ClosingScope,
  useClosingReasons,
  useLiveTime,
  useScheduleQuery,
  useTodaysShippingTypeClosingTimes,
} from 'hooks/hours';
import useAnalytics from 'hooks/use-analytics';
import {
  PauseDurationOption,
  PauseReasonOption,
  ShippingType,
  ShippingTypeLabels,
  ShippingTypeOption,
} from 'types/shop-hours';
import { durationOptions } from 'utilities/shop-hours-utils';
import { createStringComparator } from 'utilities/sorting';

import styles from './styles.module.scss';

type CanPauseForShippingType = {
  canPauseForDeliveryAndPickup: boolean;
  canPauseForDelivery: boolean;
  canPauseForPickup: boolean;
};

type Props = {
  canPauseForShippingTypes: CanPauseForShippingType;
  closedForTodayDate: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: ({
    startTime,
    endTime,
    callback,
  }: {
    startTime: string;
    endTime: string;
    callback: () => void;
  }) => void;
  selectedPausingReasonId: number | null;
  selectedShippingType: ShippingType | null;
  setSelectedReasonId: (reasonId: number) => void;
  setShippingType: (shippingType: ShippingType) => void;
  shopId: string;
  shopTimezone: string;
};

const defaultDuration = 15;

const PauseOrdersModal = ({
  canPauseForShippingTypes = {
    canPauseForDeliveryAndPickup: false,
    canPauseForDelivery: false,
    canPauseForPickup: false,
  },
  closedForTodayDate,
  isOpen,
  onClose,
  onSubmit,
  selectedPausingReasonId,
  selectedShippingType,
  setSelectedReasonId,
  setShippingType,
  shopId,
  shopTimezone,
}: Props) => {
  const { data: shopSchedule, isLoading: isLoadingSchedule } =
    useScheduleQuery(shopId);

  const { data: pausingReasonOptions, isLoading: pausingReasonsLoading } =
    useClosingReasons(ClosingScope.Pause);

  const {
    trackSelectedPauseShopService,
    trackClickedReasonForPausing,
    trackSelectedReasonForPausing,
    trackClickedPauseDuration,
    trackSelectedPauseDuration,
    trackSubmitPauseShop,
  } = useAnalytics();

  const [selectedDuration, setSelectedDuration] = useState(defaultDuration);

  const handleOnClose = () => {
    setSelectedDuration(defaultDuration);
    onClose();
  };

  const sortedReasons = useMemo(() => {
    if (!pausingReasonOptions) {
      return [];
    }

    const comparator = createStringComparator('label');

    return pausingReasonOptions
      .map((it) => ({ label: it.label, value: it.id }))
      .sort(comparator);
  }, [pausingReasonOptions]);

  const shippingOptions: ShippingTypeOption[] = [
    {
      value: ShippingType.DeliveryAndPickup,
      disabled: !canPauseForShippingTypes.canPauseForDeliveryAndPickup,
    },
    {
      value: ShippingType.Delivery,
      disabled: !canPauseForShippingTypes.canPauseForDelivery,
    },
    {
      value: ShippingType.Pickup,
      disabled: !canPauseForShippingTypes.canPauseForPickup,
    },
  ];

  const startTime = useLiveTime({ timezone: shopTimezone });
  const endTime = startTime.clone().add(selectedDuration, 'minutes');

  const { latestDeliveryTime, latestPickupTime, latestDeliveryAndPickupTime } =
    useTodaysShippingTypeClosingTimes(shopSchedule, shopTimezone);

  const availableDurationOptions = useMemo(() => {
    const getAvailableDrationOptions = (
      lastPossibleEndTime: Moment | undefined,
      canPauseForShippingType: boolean,
    ) => {
      if (!canPauseForShippingType || !lastPossibleEndTime) {
        return [durationOptions[0]];
      }

      // We want to always at least reach the latest closing time when pausing within an hour of closing,
      // so if we can pause at all and enter this modal we can pause for at least 15 minutes
      const availableDurationOptions = [
        durationOptions[0] as (typeof durationOptions)[number],
      ];
      let nextEndTime = startTime
        .clone()
        .add(durationOptions[0].value, 'minutes');

      if (nextEndTime.isSameOrAfter(lastPossibleEndTime)) {
        return availableDurationOptions;
      }

      for (const duration of durationOptions.slice(1)) {
        availableDurationOptions.push(duration);

        nextEndTime = startTime.clone().add(duration.value, 'minutes');
        if (nextEndTime.isSameOrAfter(lastPossibleEndTime)) {
          break;
        }
      }

      return availableDurationOptions;
    };

    const latestTimeForSelectedShippingType = !selectedShippingType
      ? undefined
      : selectedShippingType === ShippingType.Delivery
        ? latestDeliveryTime
        : selectedShippingType === ShippingType.Pickup
          ? latestPickupTime
          : latestDeliveryAndPickupTime;

    const canPauseForSelectedShippingType = !selectedShippingType
      ? false
      : selectedShippingType === ShippingType.Delivery
        ? canPauseForShippingTypes.canPauseForDelivery
        : selectedShippingType === ShippingType.Pickup
          ? canPauseForShippingTypes.canPauseForPickup
          : canPauseForShippingTypes.canPauseForDeliveryAndPickup;

    return getAvailableDrationOptions(
      latestTimeForSelectedShippingType,
      canPauseForSelectedShippingType,
    );
  }, [
    canPauseForShippingTypes.canPauseForDelivery,
    canPauseForShippingTypes.canPauseForDeliveryAndPickup,
    canPauseForShippingTypes.canPauseForPickup,
    latestDeliveryAndPickupTime,
    latestDeliveryTime,
    latestPickupTime,
    selectedShippingType,
    startTime,
  ]);

  const selectedDurationLabel = availableDurationOptions
    .find((option) => option.value === selectedDuration)
    ?.label.toLowerCase();

  const handleShippingTypeOnChange = (value: ShippingType) => {
    trackSelectedPauseShopService({
      shopId,
      shippingType: ShippingTypeLabels[value].toLowerCase(),
    });
    setShippingType(value);
  };

  const handleReasonMenuOpened = () => trackClickedReasonForPausing({ shopId });

  const handleReasonSelected = (
    selectedOption: SingleValue<PauseReasonOption>,
  ) => {
    if (selectedOption) {
      trackSelectedReasonForPausing({
        shopId,
        reason: selectedOption.label.toLowerCase(),
        reasonId: selectedOption.value,
      });
      setSelectedReasonId(selectedOption.value);
    }
  };

  const handleDurationMenuOpened = () => trackClickedPauseDuration({ shopId });

  const handleDurationSelected = (
    selectedOption: SingleValue<PauseDurationOption>,
  ) => {
    if (selectedOption) {
      trackSelectedPauseDuration({
        shopId,
        duration: selectedOption.value,
      });
      setSelectedDuration(selectedOption.value);
    }
  };

  const handleSubmit = (start: Moment, end: Moment) => {
    if (!selectedShippingType) {
      return;
    }

    const startTime = start.clone().utc().format();
    const endTime = end.clone().utc().format();

    const selectedShippingTypeLabel =
      ShippingTypeLabels[selectedShippingType].toLowerCase();

    const selectedReasonLabel = sortedReasons
      .find((reason) => reason.value === selectedPausingReasonId)
      ?.label.toLowerCase();

    trackSubmitPauseShop({
      shopId,
      shippingType: selectedShippingTypeLabel,
      reason: selectedReasonLabel,
      reasonId: selectedPausingReasonId,
      duration: selectedDuration,
    });

    onSubmit({
      startTime,
      endTime,
      callback: () => setSelectedDuration(defaultDuration),
    });
  };

  return (
    <Modal
      header="Pause Orders"
      isFullScreenMobile
      isOpen={isOpen}
      isYesButtonDisabled={
        selectedShippingType === null || selectedPausingReasonId === null
      }
      onClickYes={() => handleSubmit(startTime, endTime)}
      onClickNo={handleOnClose}
      onRequestClose={handleOnClose}
      noButtonText="Cancel"
      yesButtonText="Pause Orders"
    >
      <div className={styles.contentWrapper}>
        <Suspended isLoading={pausingReasonsLoading || isLoadingSchedule}>
          <div className={styles.copyWrapper}>
            <span>Pause Orders for </span>
            <span className={styles.bold}>{closedForTodayDate}?</span>
          </div>

          <RadioGroup
            className={styles.radioButton}
            data-chameleon-target="Pause Service Options"
            label="Select service to pause"
            onChange={(value) =>
              handleShippingTypeOnChange(value as ShippingType)
            }
            orientation="horizontal"
            value={selectedShippingType || undefined}
            variant="card"
          >
            {shippingOptions.map((option) => (
              <Radio
                isDisabled={option.disabled}
                key={option.value}
                label={ShippingTypeLabels[option.value]}
                value={option.value}
              />
            ))}
          </RadioGroup>
          <div
            data-chameleon-target="Pause Reason Select"
            className={styles.selectContainer}
          >
            <Label htmlFor="pause-reason-select">Reason for pause</Label>
            <Select
              inputId="pause-reason-select"
              options={sortedReasons}
              value={sortedReasons.find(
                (option) => option.value === selectedPausingReasonId,
              )}
              onChange={handleReasonSelected}
              onMenuOpen={handleReasonMenuOpened}
              maxMenuHeight={180}
            />
          </div>
          <div
            data-chameleon-target="Pause Duration Select"
            className={styles.selectContainer}
          >
            <Label htmlFor="pause-duration-select">Pause duration</Label>
            <Select
              inputId="pause-duration-select"
              options={availableDurationOptions}
              value={availableDurationOptions.find(
                (option) => option.value === selectedDuration,
              )}
              onChange={handleDurationSelected}
              onMenuOpen={handleDurationMenuOpened}
              maxMenuHeight={180}
            />
          </div>
          <div className={styles.copyWrapper}>
            Don’t worry, you can unpause at any time.
          </div>
          {selectedDurationLabel && (
            <div className={styles.copyWrapper}>
              <span>If you pause orders for the duration of </span>
              <span className={styles.bold}>{selectedDurationLabel}, </span>
              <span>
                you will automatically be accepting new orders at{' '}
                <span className={styles.bold}>
                  {endTime.format('h:mma - dddd, MMMM D')}.
                </span>
              </span>
            </div>
          )}
        </Suspended>
      </div>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PauseOrdersModal;
