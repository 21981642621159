import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Pause } from 'types/shop-hours';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getOpenForTodayQueryKey } from './use-open-for-today';
import { getPausingsQueryKey } from './use-pausings';

export const useCreatePauseMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newPause: Pause) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/pausings`,
        snakeCaseKeys(newPause),
      );

      return camelCaseKeys(response.data) as string;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getPausingsQueryKey(shopId), [data]);
      queryClient.invalidateQueries(getOpenForTodayQueryKey(shopId));
    },
  });
};
