import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { OpenForToday } from 'types/open-for-today';
import { camelCaseKeys } from 'utilities/objects';

export const getOpenForTodayQueryKey = (shopId: string): QueryKey => [
  shopId,
  'openForToday',
];

export const useOpenForTodayQuery = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getOpenForTodayQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/open_for_today`,
      );
      return camelCaseKeys(response.data) as OpenForToday;
    },
  });
};
