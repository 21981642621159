import { useQuery } from '@tanstack/react-query';

import { createFilters } from 'components/analytics/orders/products-by-sales/filters';
import useApi from 'hooks/use-api';
import { ProductSalesMetricsResponse } from 'types/opas';
import { OrderTypes } from 'types/orders';

type Values = {
  start: string;
  finish: string;
  orderType: OrderTypes;
  selectedCategoryId: string;
};

export const useProductSalesMetricsQuery = (shopId: string, values: Values) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [
      shopId,
      {
        startDate: values.start,
        endDate: values.finish,
        orderType: values.orderType,
        selectedCategoryId: values.selectedCategoryId,
      },
      'metrics-product-sales',
    ],
    queryFn: async () => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/metrics/product_sales`,
        createFilters(
          values.start,
          values.finish,
          values.orderType,
          values.selectedCategoryId,
        ),
      );

      return response as ProductSalesMetricsResponse;
    },
  });
};
