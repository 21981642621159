import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AnalyticsTile from 'components/shared/analytics-tile';
import PieChart from 'components/shared/charts/pie';
import { useCustomerTotalMetricsQuery } from 'hooks/metrics/use-customer-total-metrics-query';
import { useShopQuery } from 'hooks/shops';
import * as paths from 'routes/paths';
import { CustomerLoyaltyStatus, CustomerType } from 'types/customers';
import { toMoneyString } from 'utilities/currency';
import { capitalize } from 'utilities/strings';

import styles from './styles.module.scss';

const colorScheme = {
  loyal: 'var(--op-color-graph1)',
  new: 'var(--op-color-graph2)',
  repeat: 'var(--op-color-graph3)',
  guest: 'var(--op-color-graph4)',
};

const isDataEmpty = (data) => {
  return !Object.values(data?.customerMetrics).some((value) => value.count > 0);
};

const convertApiToChartData = (data) => {
  const customerMetrics = data?.customerMetrics;

  if (!customerMetrics) {
    return [];
  }

  const { loyal, new: newCustomers, repeat, guest } = customerMetrics;

  const orderCountsAndValues = {
    new: newCustomers,
    loyal,
    repeat,
    guest,
  };

  return Object.entries(orderCountsAndValues).map(
    ([key, { count, orderValue }]) => ({
      id: capitalize(key),
      value: count,
      color: colorScheme[key],
      legendText: `${count} people・${toMoneyString(orderValue / 100)}`,
    }),
  );
};

const TileHeaderTooltipText = () => (
  <>
    <b>New Customers:</b>
    <br />
    New - First-time order
    <br />
    <br />
    <b>Loyal Customers:</b>
    <br />
    Loyal - Ordered more than 3 times/3 months.
    <br />
    <br />
    <b>Repeat Customers:</b>
    <br />
    Repeat – Ordered less than 3 times/3 months.
    <br />
    <br />
    <b>Guest Customers:</b>
    <br />
    These users checked out as guests.
  </>
);

const CustomerPieTile = ({ shopId, startDate, endDate, orderType }) => {
  const navigate = useNavigate();

  const { data: shop } = useShopQuery(shopId);

  const isRosEnabled = shop?.rosEnabled ?? false;
  const linkToCustomerAnalyticsPage = isRosEnabled;

  const { data, isLoading } = useCustomerTotalMetricsQuery(shopId, {
    start: startDate,
    end: endDate,
    orderType,
  });

  const navigateToCustomers = (clickedList) => {
    // Set customer list type using history state
    const validList = Object.values(CustomerLoyaltyStatus).includes(
      clickedList,
    );

    let historyState = {};

    if (validList) {
      historyState.customerList = clickedList;
    }

    // Set order type using local storage
    const validOrderType = Object.values(CustomerType).includes(orderType);

    if (validOrderType) {
      historyState.orderType = orderType;
    }

    // Reset date range to default (past 30 days)
    // by clearing date range in local storage
    historyState.resetDateRange = true;

    // Navigate
    if (clickedList === 'guest') {
      navigate(paths.getCustomerAnalyticsPath(shopId), { state: historyState });
    } else {
      navigate(paths.getCustomerSegmentsPath(shopId), { state: historyState });
    }
  };

  const chartOnClick = (node) =>
    linkToCustomerAnalyticsPage && navigateToCustomers(node.id.toLowerCase());

  const legendOnClick = (data) =>
    linkToCustomerAnalyticsPage && navigateToCustomers(data.toLowerCase());

  const onMouseEnter = (_data, event) =>
    linkToCustomerAnalyticsPage &&
    (event.currentTarget.style.cursor = 'pointer');

  return (
    <AnalyticsTile
      data-chameleon-target="Customer Metrics CustomerPie PieChart"
      title="Your Customers"
      footerText="Reward your customers with a promo!"
      linkText="Create Promo Code"
      link={paths.newPromoCode(shopId)}
      headerTooltip={<TileHeaderTooltipText />}
    >
      <PieChart
        isEmpty={isLoading || isDataEmpty(data)}
        data={convertApiToChartData(data)}
        chartOnClick={chartOnClick}
        onMouseEnter={onMouseEnter}
        legendTitleClassName={
          linkToCustomerAnalyticsPage ? styles.legendTitle : ''
        }
        legendOnClick={legendOnClick}
      />
    </AnalyticsTile>
  );
};

CustomerPieTile.propTypes = {
  shopId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
  showTileCaption: PropTypes.bool,
};

CustomerPieTile.defaultProps = {
  shopId: '',
  orderType: 'online',
  showTileCaption: true,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerPieTile;
