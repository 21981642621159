import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'crust';

import { CloseButton } from 'components/shared/icon-button';
import { RHFTextField } from 'components/shared/rhf-text-field';
import { ShopReferral } from 'components/shop-referral';
import * as paths from 'routes/paths';

import Item from '../item';
import PsmInfoDrawerItem from '../psm-info-drawer-item';
import SubDrawer from '../sub-drawer';

import styles from './styles.module.scss';

const Drawer = ({
  activeShop,
  activeSubMenu,
  closeDrawer,
  isDrawerOpen,
  navigationItems,
  setActiveSubMenu,
  shopId,
  shops,
  trackNavigationItemClick,
  verificationCode,
  isAdmin,
}) => {
  const { logout } = useAuth0();

  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      shopId: '',
    },
  });

  const shopSelectorItem = {
    label: activeShop?.name,
    url: paths.shop(activeShop?.shopId),
    items: shops
      .filter((shop) => activeShop?.shopId !== shop.shopId)
      .map((shop) => ({
        label: shop.name,
        url: paths.shop(shop.shopId),
      })),
    dataTestId: 'mobileNavShopSelectorButton',
  };
  const onSubmit = ({ shopId }) => {
    const shopUrl = paths.shop(shopId);
    navigate(shopUrl);
  };

  return (
    <div
      className={cx(styles.navigationDrawer, {
        [styles.openDrawer]: isDrawerOpen,
      })}
    >
      <div className={styles.logos}>
        <CloseButton label="Close navigation" onClick={closeDrawer} />
      </div>
      <div
        className={cx(styles.contentContainer, {
          [styles.withActiveSubMenu]: activeSubMenu,
        })}
      >
        <div className={styles.subMenu}>
          <div className={styles.shopSelector}>
            {isAdmin ? (
              <>
                <Item {...shopSelectorItem} onClick={setActiveSubMenu} />
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                  <RHFTextField
                    control={control}
                    name="shopId"
                    placeholder="Enter Shop Id"
                  />
                  <Button
                    type="submit"
                    data-testid="shopSelectionPageContinueButton"
                    className={styles.arrowButton}
                  >
                    {'>'}
                  </Button>
                </form>
              </>
            ) : (
              <Item {...shopSelectorItem} onClick={setActiveSubMenu} />
            )}
          </div>
          <PsmInfoDrawerItem
            setActiveSubMenu={setActiveSubMenu}
            shopId={shopId}
            verificationCode={verificationCode}
          />
          {navigationItems.map((section) => (
            <div className={styles.section} key={section.id}>
              {section.items.map((item) => (
                <Item
                  {...item}
                  key={item.label}
                  onClick={setActiveSubMenu}
                  trackNavigationItemClick={trackNavigationItemClick}
                />
              ))}
            </div>
          ))}
          <div className={styles.section}>
            <button
              className={styles.logout}
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
              type="button"
              data-testid="mobileNavLogoutButton"
            >
              Log Out
            </button>
          </div>
          <ShopReferral shopId={shopId} />
        </div>
        {activeSubMenu && (
          <SubDrawer
            activeSubMenu={activeSubMenu}
            clearActiveSubMenu={() => setActiveSubMenu(null)}
            trackNavigationItemClick={trackNavigationItemClick}
          />
        )}
      </div>
    </div>
  );
};

Drawer.propTypes = {
  activeShop: PropTypes.object,
  activeSubMenu: PropTypes.object,
  closeDrawer: PropTypes.func,
  isDrawerOpen: PropTypes.bool,
  navigationItems: PropTypes.array,
  setActiveSubMenu: PropTypes.func,
  shopId: PropTypes.string.isRequired,
  shops: PropTypes.array.isRequired,
  trackNavigationItemClick: PropTypes.func,
  verificationCode: PropTypes.string,
  isAdmin: PropTypes.bool,
};

Drawer.defaultProps = {
  activeShop: null,
  activeSubMenu: null,
  closeDrawer: () => {},
  navigationItems: [],
  setActiveSubMenu: () => {},
  trackNavigationItemClick: () => {},
  isDrawerOpen: false,
  isAdmin: false,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Drawer;
