import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

type Values = {
  start: string;
  finish: string;
  orderType: string;
};

export const useCustomerTotalMetricsQuery = (
  shopId: string,
  values: Values,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [
      shopId,
      {
        startDate: values.start,
        endDate: values.finish,
        orderType: values.orderType,
      },
      'metrics-customer-totals',
    ],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/metrics/customers?${queryString.stringify(
          snakeCaseKeys(values),
        )}`,
      );
      return camelCaseKeys(response);
    },
  });
};
